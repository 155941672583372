

























import { Vue, Component } from 'vue-property-decorator'
import { Modal, Steps, HttpGet, HttpPost, Loader, Card, PostButton, Action, PostActionButton } from '../../../lib/mavejs'
import CampaignForm from '@/views/campaigns/form/CampaignForm.vue'
import TargetingForm from '@/views/campaigns/form/TargetingForm.vue'
import GeoForm from '@/views/campaigns/form/GeoForm.vue'
import SettingsForm from '@/views/campaigns/form/SettingsForm.vue'
import AdsForm from '@/views/campaigns/form/AdsForm.vue'
import { url } from '@/functions'
import { initialVideoForm } from '@/store/campaigns'
import UrlCheck from '@/components/campaigns/form/UrlCheck.vue'

@Component({
  components: { UrlCheck, Modal, Steps, HttpGet, HttpPost, Loader, Card, PostButton, Action, PostActionButton },
  methods: { url }
})
export default class CreateCampaign extends Vue {
  steps = [
    { icon: 'analytics', label: 'Campaign', component: CampaignForm },
    { icon: 'people_alt', label: 'Targeting', component: TargetingForm },
    { icon: 'flag', label: 'Geo\'s', component: GeoForm },
    { icon: 'settings', label: 'Settings', component: SettingsForm },
    { icon: 'featured_video', label: 'Ads', component: AdsForm }
  ]

  isFetching = false
  show = false

  $refs: {
    submit: HttpPost;
    check: UrlCheck;
  }

  mounted () {
    if (this.$store.state.campaigns.form.videosEdit[this.$route.params.id] === undefined) {
      this.$store.state.campaigns.form.videosEdit[this.$route.params.id] = initialVideoForm
    }

    this.show = true
  }

  submit () {
    this.$refs.submit.post()
      .then(() => {
        this.$store.commit('clearForm', { name: 'videosEdit', id: this.$route.params.id })
        this.$store.state.campaigns.form.videosEdit[this.$route.params.id] = undefined
        this.$router.push('/campaigns?refresh=true')
      })
  }
}
